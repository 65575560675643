import React from 'react';
import Name from '../../../components/Account/Name';
import PrivateRoute from '../../../components/PrivateRoute';
import ContextProvider from '../../../components/ContextProvider';

const NamePageEnAu = () => (
  <ContextProvider localeCode="en-AU">
    <PrivateRoute path="/en-AU/account/name" component={Name} />
  </ContextProvider>
);

export default NamePageEnAu;
